.app {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .app_container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .auth_container {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .singleapp_app {
  }

  .company_app {
  }

  .intern_app {
  }

  .university_app {
  }
}
