.CircularProgressbar {
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
  border: 3px solid #a1abc5;
  cursor: pointer;

  .CircularProgressbar-trail {
    stroke: #fff;
  }

  .CircularProgressbar-path {
    stroke: #50ef8b;
  }
}

.CircularProgressbarFull {
  background-color: #50ef8b;
  border-radius: 50%;
  padding: 2px;
  border: 3px solid #50ef8b;
  cursor: pointer;

  .CircularProgressbar-trail {
    stroke: #fff;
  }

  .CircularProgressbar-path {
    stroke: #50ef8b;
  }
}
