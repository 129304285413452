/* ********************************  thin 100 *********************************************** */
@font-face {
  font-family: "Causten-Thin";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-Thin.ttf");
  /* font-weight: 100; */
}

@font-face {
  font-family: "Causten-ThinOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-ThinOblique.ttf");
  /* font-weight: 100;
    font-style: italic; */
}

/* ********************************  very light 200 *********************************************** */
@font-face {
  font-family: "Causten-ExtraLight";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-ExtraLight.ttf");
  /* font-weight: 200; */
}

@font-face {
  font-family: "Causten-ExtraLightOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-ExtraLightOblique.ttf");
  /* font-weight: 200;
    font-style: italic; */
}

/* ********************************  light 300 *********************************************** */
@font-face {
  font-family: "Causten-Light";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-Light.ttf");
  /* font-weight: 300; */
}

@font-face {
  font-family: "Causten-LightOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-LightOblique.ttf");
  /* font-weight: 300;
    font-style: italic; */
}

/* ********************************  regular 400 *********************************************** */
@font-face {
  font-family: "Causten-Regular";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-Regular.ttf");
  /* font-weight: 500; */
}

@font-face {
  font-family: "Causten-RegularOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-RegularOblique.ttf");
  /* font-weight: 500;
    font-style: italic; */
}

/* ********************************  normal 500 *********************************************** */
@font-face {
  font-family: "Causten-Medium";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-Medium.ttf");
  /* font-weight: normal; */
}

@font-face {
  font-family: "Causten-MediumOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-MediumOblique.ttf");
  /* font-weight: normal;
    font-style: italic; */
}

/* ********************************  semi bold 600 *********************************************** */
@font-face {
  font-family: "Causten-SemiBold";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-SemiBold.ttf");
  /* font-weight: 600; */
}

@font-face {
  font-family: "Causten-SemiBoldOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-SemiBoldOblique.ttf");
  /* font-weight: 600;
    font-style: italic; */
}

/* ********************************  bold 700 *********************************************** */
@font-face {
  font-family: "Causten-Bold";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-Bold.ttf");
  /* font-weight: 700; */
}

@font-face {
  font-family: "Causten-BoldOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-BoldOblique.ttf");
  /* font-weight: 700;
    font-style: italic; */
}

/* ******************************** very bold 800 *********************************************** */
@font-face {
  font-family: "Causten-ExtraBold";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-ExtraBold.ttf");
  /* font-weight: 800; */
}

@font-face {
  font-family: "Causten-ExtraBoldOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-ExtraBoldOblique.ttf");
  /* font-weight: 800;
    font-style: italic; */
}

/* ********************************  black 900 *********************************************** */
@font-face {
  font-family: "Causten-Black";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-Black.ttf");
  /* font-weight: 900; */
}

@font-face {
  font-family: "Causten-BlackOblique";
  src: url("../../assets/fonts/CaustenRound/CaustenRound-BlackOblique.ttf");
  /* font-weight: 900;
    font-style: italic; */
}

@import url("https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap");
