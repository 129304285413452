/* #########################   scroolbar  ########################################## */
*::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
  padding: 5px 0px;
  position: fixed;
  right: 0;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

*::-webkit-scrollbar-track-piece {
  background-color: white;
  border-radius: 10px;
  margin: 5px;
  background-clip: content-box;
  border: 5px solid transparent;
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* background-color: red; */
  /* background-color: transparent; */
  border-radius: 10px;
  width: 5px;
}

/* #########################   scroolbar  ########################################## */
