.tab_menu_container {
  .MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        border-bottom: 1px solid #ccc;

        button {
          padding: 5px;
          margin-right: 5px;
          font-family: Causten-Medium;
          text-transform: capitalize;
          color: #000;
          font-size: 18px;

          &.Mui-selected {
            color: orange;
          }

          @media (max-width: 600px) {
            // width: 100%;
            flex: 1;
          }
        }
      }

      .MuiTabs-indicator {
        background-color: orange;
        padding: 2px;
        border-radius: 2px;
      }
    }
  }

  .tab_menu_panel {
    margin-top: 20px;
  }
}
