.MuiPickersPopper-root {
  // background-color: red;
  // padding: 10px;

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper {
    // background-color: blue;
    border-radius: 8px;

    .MuiCalendarOrClockPicker-root {
      div {
        .MuiCalendarPicker-root {
          .MuiPickersCalendarHeader-root {
            // background-color: #ff9737;

            .MuiPickersCalendarHeader-labelContainer {
              font-family: "Causten-SemiBold";
            }
          }

          .MuiPickersFadeTransitionGroup-root.MuiCalendarPicker-viewTransitionContainer {
            // background-color: #ff9737;
            div {
              div {
                //weeks header
                .MuiDayPicker-header {
                  span {
                    // font-family: "Causten-SemiBold" !important;
                    // color: #ff9737;
                  }
                  // background-color: yellow;
                }
                //numbers header
                .PrivatePickersSlideTransition-root.MuiDayPicker-slideTransition {
                  // font-family: "Causten-Regular" !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ********************** en üstteki ay yıl ********************* */
.PrivatePickersFadeTransitionGroup-root {
  font-family: "Causten-SemiBold";
  font-size: 15px;
  /* background-color: red!important; */
}

/* ********************** seçili_gün ********************* */
.PrivatePickersSlideTransition-root .Mui-selected {
  color: #fff;
  background-color: #ff9737 !important;
  /* background-color: red !important; */
}
/* ********************** datepicker all days  ************************ */
.MuiPickersDay-root {
  font-family: "Causten-Medium" !important;
  font-size: 14px !important;
  /* background-color: red !important; */
}

/* **********************  choosen_month ********************* */
.MuiMonthPicker-root .Mui-selected {
  font-family: "Causten-Regular";
  font-size: 14px;
  color: #fff;
  background-color: #ff9737 !important;
}
/* ********************** month_when_hover ********************* */
.PrivatePickersMonth-root:hover {
  color: #ff9737 !important;
  background-color: #fffaf5 !important;
  font-family: "Causten-Regular" !important;
  font-size: 14px;
}
/* ********************** month_all ********************* */
.PrivatePickersMonth-root {
  font-family: "Causten-Regular" !important;
  font-size: 16px !important;
}

/* **********************  all_years ********************* */
.PrivatePickersYear-root .PrivatePickersYear-yearButton {
  font-family: "Causten-Regular";
  font-size: 14px;
}
/* **********************  choosen_year ********************* */
.PrivatePickersYear-root .Mui-selected {
  font-family: "Causten-Regular";
  font-size: 14px;
  color: #fff;
  background-color: #ff9737 !important;
}
/* ********************** year_when_hover ********************* */
.PrivatePickersYear-root .PrivatePickersYear-yearButton:hover {
  color: #ff9737 !important;
  background-color: #fffaf5 !important;
}

/* ********************** all material input  ************************ */
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  border: none !important;
  outline: none !important;
  font-size: 14px !important;
  line-height: 14px !important;
  font-family: "Causten-SemiBold" !important;
  color: rgb(14, 20, 54) !important;
}
.MuiOutlinedInput-root fieldset {
  border: none !important;
  outline: none !important;
}

/* ********************** datepicker input un üstündeki bir element ************************ */
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd,
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart {
  background-color: transparent;
  width: 100%;
  position: absolute;
  left: 0;
  border-radius: 0;
  height: 100%;

  display: flex;
  justify-content: flex-end;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart {
  justify-content: start;
}

/* ********************** datepicker_week_letter_container   ************************ */
.MuiTypography-caption {
  font-size: 13px !important;
  font-family: "Causten-SemiBold" !important;
}

/* ********************** datepicker_title_month_and_year_container   ************************ */
.css-1dozdou .css-k008qs {
  display: none !important;
}
.css-1dozdou {
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
}

/* ************** for small screen calander ******************** */
@media screen and (max-width: 350px) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded > div > div {
    /* background-color: green; */
    width: 100% !important;
  }
  .MuiCalendarPicker-root {
    /* background-color: red !important; */
    width: 100% !important;
  }
}
