.quill.react_quill {
  // background-color: red;

  .ql-toolbar {
    // background-color: yellow;
    margin-bottom: 0px !important; // aradaki boşluk oluşmaması için

    .ql-formats {
      .ql-header.ql-picker {
        .ql-picker-label {
          padding-left: 0px; // sola iyice yanaşması için
        }
      }
    }

    &:nth-child(1) {
      border: none;
      margin-bottom: 20px;
      font-family: "Causten-Medium";
      margin-left: 0px;
      padding-left: 0px;
    }

    &:nth-child(2) {
      display: none;
    }
  }

  .ql-container {
    // background-color: blue;
    border-radius: 8px;
    border: 1px solid #e3e7f2 !important;
    font-family: "Causten-Medium";
    font-size: 18px;
    background-color: #fff;

    p {
      color: #70798d;
      font-size: 14px;
      padding: 0;
      margin: 0;
      line-height: 24px;
    }
  }
}

// .react_quill > .ql-toolbar:nth-child(1) {
//   border: none;
//   margin-bottom: 20px;
//   font-family: "Causten-Medium";
//   margin-left: 0px;
//   padding-left: 0px;
// }

// .react_quill > .ql-toolbar:nth-child(2) {
//   display: none;
// }

// .react_quill > .ql-container {
//   border-radius: 8px;
//   border: 1px solid #e3e7f2 !important;
//   font-family: "Causten-Medium";
//   font-size: 18px;
//   background-color: #fff;
// }

.react_quill.error_editor > .ql-container {
  border: 1px solid #db0404 !important;
}

// .quill > .ql-container p {
//   color: #70798d;
//   font-size: 14px;
//   padding: 0;
//   margin: 0;
//   line-height: 24px;
// }

/* #######################  for read only #################################### */
.react_quill.onlytext > .ql-toolbar {
  display: none !important;
  height: 100px !important;
}

.react_quill.onlytext > .ql-container {
  border: none !important;
  color: black;
  font-size: 14px;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-family: "Causten-Regular";
  font-style: normal;
}

.react_quill_mystyle > .ql-container {
  min-height: 250px !important;
}
.react_quill_mystyle > .ql-container > * {
  /* background-color: red !important; */
  height: 250px !important;
}

.react_quill_without_padding > * > * {
  /* background-color: red !important; */
  padding: 0 !important;
}

.rq_pale_text > * > * {
  color: #70798d !important;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card_item_quill > * > * {
  /* height: auto !important; */
  /* background-color: red; */
  font-size: 12px !important;
  padding: 0 !important;
  color: #666 !important;
}

.react_quill_mystyle2 .ql-editor {
  padding: 0 !important;
  color: #999 !important;
  font-size: 12px !important;
}

.react_quill .ql-editor::before {
  color: #bbbdc1 !important;
  font-size: 14px !important;
}
