@import url("./_fonts.scss");

:root {
  --primary: #000000;
  //   --background: #ffffff;
  //   --background-dim: #f3f3f3;
  //   --background-dim-light: #f9f9f9;
  //   --primary-foreground: #ffffff;
  //   --foreground: #161616;
  //   --base-unit: 8px;
}
