@import url("./abstractsScss/abstract.scss");
@import url("./appsScss/apps.scss");
@import url("./componentsScss/components.scss");
@import url("./layoutsScss/layout.scss");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#root {
  flex: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
