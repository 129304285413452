/* #####################   autocomplete açılır list country city and anothers  ################## */

.MuiAutocomplete-popper > div > ul {
  border: none;
  /* height: 900px !important; */
  /* padding: 10px; */
}

/* #########################   country popper for mobile  ########################################## */
@media screen and (max-width: 600px) {
  .MuiAutocomplete-popper {
    width: 100% !important;
    height: 100% !important;
    /* background-color: red!important; */
  }

  .MuiAutocomplete-popper > div {
    width: 100%;
    border: none;
    border-radius: 0px !important;
    height: 100% !important;
  }

  .MuiAutocomplete-popper > * > * {
    width: 100%;
    box-shadow: none !important;
  }
}
/* #########################   country popper for mobile  ########################################## */
