.sd-root-modern.sd-root-modern--mobile {
  background-color: #fff;

  form {
    background-color: #fff;

    .sd-container-modern {
      background-color: #fff;

      .sd-body.sd-body--static {
        // ***********************

        //#######################################################################
        .sd-page.sd-body__page.sd-page__empty-header {
          // ***********************

          .sd-row.sd-clearfix.sd-page__row {
            // ***********************

            .sd-element.sd-question.sd-row__question.sd-element--with-frame {
              box-shadow: none;

              .sd-selectbase__label {
                // ***********************

                .sd-item__decorator.sd-checkbox__decorator {
                  // ***********************
                }

                .sd-item__control-label {
                  font-family: "Causten-Medium";
                  color: #999;
                }
              }
            }
          }
        }
        //#######################################################################

        //#######################################################################
        .sd-action-bar.sd-footer.sd-body__navigation.sd-clearfix {
          // ***********************
          padding: 0px;

          .sv-action {
            // ***********************

            .sd-btn.sd-btn--action.sd-navigation__complete-btn {
              // ***********************
              background-color: #191922;
              border-radius: 8px;
            }
          }
        }
        //#######################################################################
      }
    }
  }
}
